import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import OurServices from "./pages/OurServices";
import AboutUs from "./pages/AboutUs";
import Portfolios from "./pages/Portfolios";
import OurWork from "./pages/OurWork";
import Hunter from "./pages/Hunter";
import ContactUs from "./pages/ContactUs";
import OurClient from "./pages/OurClient";
import PortfoioPage from "./pages/PortfolioPage";
// import { ThemeProvider } from "./context/ThemeContext";

const TrackPageView = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-0Q6Q5XWGWD", {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return null;
};

function App() {
  return (
    <>
      <React.Suspense fallback={<>Loading...</>}>
        <Router>
          <TrackPageView />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/our-services" element={<OurServices />} />
            <Route path="/portfolios" element={<Portfolios />} />
            <Route path="/our-work" element={<OurWork />} />
            <Route path="/our-client" element={<OurClient />} />
            <Route path="/our-work/hunter" element={<Hunter />} />
            <Route path="/our-work/:id" element={<PortfoioPage />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </Router>
      </React.Suspense>
    </>
  );
}

export default App;
