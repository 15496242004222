import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Aos from "aos";
import { Link } from "react-router-dom";
import { useTheme } from "../context/ThemeContext";
import axios from "axios";

function ContactUs() {
  let backendURL = process.env.REACT_APP_backendURL;

  const { theme } = useTheme();

  function openMailbox1() {
    window.open("mailto:galiyaarafilms@gmail.com");
  }
  function openMailbox2() {
    window.open("mailto:connect@galiyaarafilms.com");
  }

  const handlePhoneCall1 = () => {
    const phoneNumber = "9585371030";
    const phoneUrl = `tel:${phoneNumber}`;
    window.location.href = phoneUrl;
  };
  const handlePhoneCall2 = () => {
    const phoneNumber = "9229229777";
    const phoneUrl = `tel:${phoneNumber}`;
    window.location.href = phoneUrl;
  };


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    serviceLookingFor: "",
    projectDescription: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null); // For success or error messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    try {
      const response = await axios.post(`${backendURL}/contact`, formData);
      setMessage("Message sent successfully!");
      setFormData({ name: "", email: "", serviceLookingFor: "", projectDescription: "" });
    } catch (error) {
      setMessage("Failed to send message. Please try again later.");
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Header />
      <div
        className={`sm:mt-[104px] overflow-hidden mt-[60px] ${theme === "light" ? "bg-[#ffffff]" : "bg-[#171717]"
          }`}
      >
        <div className="pt-[48px] sm:pb-[302px] pb-[50px] flex flex-col items-center">
          <div data-aos="fade-up">
            <p
              style={{ fontFamily: "GeneralSans" }}
              className={`font-[700] text-[18px] leading-[28.8px] text-center pb-[12px] ${theme === "light" ? "text-[#545454]" : "text-[#FFFFFF]"
                }`}
            >
              Contact Us
            </p>
          </div>
          <div data-aos="fade-right">
            <h6
              style={{ fontFamily: "GeneralSans" }}
              className={`max-w-[644px] pb-[20px] m-auto w-full font-[700] sm:text-[56px] text-[30px] sm:leading-[69.44px] leading-[36px] text-center ${theme === "light" ? "text-[#000000]" : "text-[#FFFFFF]"
                }`}
            >
              Let’s work together!
            </h6>
          </div>
        </div>
        <div className="bg-[#1C1C1C] sm:h-[382px] relative h-auto sm:py-0 py-[40px] sm:px-[102px] px-[10px] w-full">
          <div className="grid sm:grid-cols-2 grid-cols-1 sm:absolute relative sm:top-[-205px] top-unset sm:left-[50%] left-unset sm:translate-x-[-50%] translate-x-unset sm:px-[102px] px-[10px] w-full gap-[20px]">
            <div>
              <iframe className="w-full h-full rounded-[16px]"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3681.177598188074!2d75.82695387530377!3d22.684431679412192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDQxJzA0LjAiTiA3NcKwNDknNDYuMyJF!5e0!3m2!1sen!2sin!4v1722250639951!5m2!1sen!2sin"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="border border-[#000000] overflow-hidden bg-[#FFFFFF] rounded-[16px]">
              <div className="py-[30px] px-[34px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[700] text-[24px] leading-[29.76px] text-[#212121]"
                >
                  Address
                </h6>
                <p
                  data-aos="fade-left"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[400] text-[18px] leading-[28.8px] text-[#545454] pt-[16px]"
                >
                  2nd Floor, 283, Scheme Number 97-IV, Slice -1,
                  Near Gopur Square, Indore
                </p>
              </div>
              <div className="border-b border-[#000000]"></div>
              <div className="pt-[28px] px-[34px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[700] text-[24px] leading-[29.76px] text-[#212121]"
                >
                  Email
                </h6>
                <p
                  data-aos="fade-left"
                  onClick={openMailbox1}
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[400] cursor-pointer text-[18px] leading-[28.8px] text-[#545454] pt-[16px] pb-[8px]"
                >
                  galiyaarafilms@gmail.com
                </p>
                <p
                  data-aos="fade-right"
                  onClick={openMailbox2}
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[400] cursor-pointer text-[18px] leading-[28.8px] text-[#545454] pb-[16px]"
                >
                  connect@galiyaarafilms.com
                </p>
              </div>
              <div className="border-b border-[#000000]"></div>
              <div className="pt-[28px] px-[34px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[700] text-[24px] leading-[29.76px] text-[#212121]"
                >
                  Call Us
                </h6>
                <p
                  data-aos="fade-left"
                  style={{ fontFamily: "GeneralSans" }}
                  onClick={handlePhoneCall1}
                  className="font-[400] cursor-pointer text-[18px] leading-[28.8px] text-[#545454] pt-[16px] pb-[8px]"
                >
                  +91 9585371030
                </p>
                <p
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  onClick={handlePhoneCall2}
                  className="font-[400] cursor-pointer text-[18px] leading-[28.8px] text-[#545454] pb-[16px]"
                >
                  +91 9229229777
                </p>
              </div>
              <div className="border-b border-[#000000]"></div>
              <div className="py-[30px] px-[34px]">
                <h6
                  data-aos="fade-right"
                  style={{ fontFamily: "GeneralSans" }}
                  className="font-[700] text-[24px] leading-[29.76px] text-[#212121]"
                >
                  Follow Us
                </h6>
                <div data-aos="fade-up" className="flex gap-[14px] pt-[16px]">
                  <Link to="https://www.facebook.com/share/cUAmVfzcyjh7E7dN/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
                    <img src="assets/about/icon1.svg" alt="" />
                  </Link>
                  <Link to="https://www.linkedin.com/in/galiyaara-films-a05287170?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
                    <div className="flex">
                      <img src="assets/about/icon9.svg" alt="" />
                    </div>
                  </Link>
                  <button onClick={openMailbox1}>
                    <img src="assets/about/icon3.svg" alt="" />
                  </button>
                  <Link to="https://youtube.com/@galiyaarafilms5730?si=7RQEKis4JXv4GtAW" target="_blank" rel="noopener noreferrer">
                    <img src="assets/about/icon4.svg" alt="" />
                  </Link>
                  <Link to="https://www.instagram.com/galiyaarafilms?igsh=MW81eHY2dmhwMXF0Mw==" target="_blank" rel="noopener noreferrer">
                    <div className="flex">
                      <img src="assets/about/item10.svg" alt="" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`sm:pt-[104px] pt-[40px] sm:px-[100px] px-[20px] sm:pb-[64px] pb-[30px] ${theme === "light" ? "bg-[#FFFFFF]" : "bg-[#1c1c1c]"
            }`}
        >
          <div className="bg-[#992119] p-[16px] rounded-[30px]">
            <div className="border border-[#FFFFFF] rounded-[30px] bg-[#992119] pt-[46px]">
              <h6
                data-aos="fade-up"
                style={{ fontFamily: "GeneralSans" }}
                className="font-[700] text-center text-[40px] leading-[49.6px] text-[#FFF6F6] pb-[44px]"
              >
                Get In Touch
              </h6>
              <div className="max-w-[746px] sm:px-0 px-[10px] pb-[56px] w-full m-auto flex flex-col gap-[40px]">
                <form onSubmit={handleSubmit} className="flex flex-col gap-[20px]">
                  <input
                    data-aos="fade-right"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#ADADAD] rounded-[90px] font-[400] sm:text-[20px] text-[14px] sm:leading-[32px] leading-[20px] text-[#FFFFFF] placeholder:text-[#FFFFFF] py-[12px] sm:px-[24px] px-[16px] bg-transparent w-full outline-none mb-[20px]"
                    placeholder="Name"
                    required
                  />
                  <input
                    data-aos="fade-left"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#ADADAD] rounded-[90px] font-[400] sm:text-[20px] text-[14px] sm:leading-[32px] leading-[20px] text-[#FFFFFF] placeholder:text-[#FFFFFF] py-[12px] sm:px-[24px] px-[16px] bg-transparent w-full outline-none mb-[20px]"
                    placeholder="Email address"
                    required
                  />

                  <select
                    data-aos="fade-right"
                    name="serviceLookingFor"
                    value={formData.serviceLookingFor}
                    onChange={handleChange}
                    style={{ fontFamily: "GeneralSans" }}
                    required
                    className="border border-[#ADADAD] rounded-[90px] font-[400] sm:text-[20px] text-[14px] sm:leading-[32px] leading-[20px] text-[#FFFFFF] placeholder:text-[#FFFFFF] py-[12px] sm:px-[24px] px-[16px] bg-transparent w-full outline-none"
                  >
                    <option className="text-[#000000]" value="">What services are you looking for?</option>
                    <option className="text-[#000000]" value="film production">Film Production</option>
                    <option className="text-[#000000]" value="social media marketing">Social Media Marketing</option>
                    <option className="text-[#000000]" value="creative media agency">Creative Media Agency</option>
                  </select>
                  <textarea
                    data-aos="fade-left"
                    cols="30"
                    rows="5"
                    name="projectDescription"
                    value={formData.projectDescription}
                    onChange={handleChange}
                    placeholder="Your project description"
                    style={{ fontFamily: "GeneralSans" }}
                    className="border border-[#ADADAD] rounded-[24px] font-[400] sm:text-[20px] text-[14px] sm:leading-[32px] leading-[20px] text-[#FFFFFF] placeholder:text-[#FFFFFF] py-[12px] sm:px-[24px] px-[16px] bg-transparent w-full outline-none mb-[20px]"
                    required
                  ></textarea>
                  <button
                    data-aos="fade-up"
                    type="submit"
                    style={{ fontFamily: "GeneralSans" }}
                    className="flex items-center justify-center gap-[8px] bg-[#FFFFFF] py-[16px] px-[32px] rounded-[90px]"
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Send Message"} <img src="/assets/about/icon6.svg" alt="" />
                  </button>
                </form>
                {message && <p>{message}</p>}
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
